import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ServiceBase } from '../../../../lib/core/services/service.base';
import { SignalRService } from '../../../../lib/core/services/signalR.service';
import { AccountInfo } from '../models/account-info';
import { Address } from '../models/address';
import { OrganizationInfo } from '../models/organization-info';

const accountInfoUrl = environment.apiUrl + '/api/account';
const organizationUrl = environment.apiUrl + '/api/organization';
const accountAddressUrl = environment.apiUrl + '/api/accountaddress';
const organizationAddressUrl = environment.apiUrl + '/api/organizationaddress';

@Injectable({ providedIn: 'root' })
export class AccountService extends ServiceBase {
  constructor(http: HttpClient, signalRService: SignalRService) {
    super(http, signalRService);
  }

  private accountInfo: BehaviorSubject<AccountInfo> =
    new BehaviorSubject<AccountInfo>(new AccountInfo());

  private organizationInfo: BehaviorSubject<OrganizationInfo> =
    new BehaviorSubject<OrganizationInfo>(new OrganizationInfo());

  private addressesInfo: BehaviorSubject<Address[]> = new BehaviorSubject<
    Address[]
  >([]);

  get accountDetail$() {
    return this.accountInfo.asObservable();
  }

  setAccountDetail(data: AccountInfo) {
    this.accountInfo.next(data);
  }

  get addressesInfo$() {
    return this.addressesInfo.asObservable();
  }

  setaddressesInfo(data: Address[]) {
    this.addressesInfo.next(data);
  }

  get organizationInfo$() {
    return this.organizationInfo.asObservable();
  }

  setOrganizationInfo(data: OrganizationInfo) {
    this.organizationInfo.next(data);
  }

  getAccountSummary() {
    return this.get(accountInfoUrl).pipe(share());
  }

  getAccountDetail() {
    return this.get(accountInfoUrl + '/detail').pipe(share());
  }

  getOrganizationInfo(organizationId: string) {
    return this.get(`${organizationUrl}?organizationId=${organizationId}`).pipe(
      share()
    );
  }

  getAccountAddresses() {
    return this.get(`${accountAddressUrl}`).pipe(share());
  }

  createAccountAddress(address: Address) {
    return this.post(accountAddressUrl, address).pipe(share());
  }

  updateAccountAddress(address: Address) {
    return this.post(accountAddressUrl + '/update', address).pipe(share());
  }

  deleteAccountAddress(addressId: number) {
    return this.post(accountAddressUrl + '/delete', {
      addressId
    }).pipe(share());
  }

  getOrganizationAddresses(organizationId: string) {
    return this.get(
      `${organizationAddressUrl}?organizationId=${organizationId}`
    ).pipe(share());
  }

  createOrganizationAddress(organizationId: string, address: Address) {
    return this.post(organizationAddressUrl, {
      organizationId,
      ...address
    }).pipe(share());
  }

  updateOrganizationAddress(organizationId: string, address: Address) {
    return this.post(organizationAddressUrl + '/update', {
      organizationId,
      ...address
    }).pipe(share());
  }

  deleteOrganizationAddress(organizationId: string, addressId: number) {
    return this.post(organizationAddressUrl + '/delete', {
      organizationId,
      addressId
    }).pipe(share());
  }

  updateOrganizationPhoneNumber(
    organizationId: string,
    mobilePhoneNumber: string,
    landPhoneNumber: string
  ) {
    return this.post(organizationUrl + '/update-phonenumber', {
      organizationId,
      mobilePhoneNumber,
      landPhoneNumber
    }).pipe(share());
  }

  updateOrganizationLandPhone(organizationId: string, landPhone: string) {
    return this.post(organizationUrl + '/update-landphone', {
      organizationId,
      landPhone
    }).pipe(share());
  }

  updateOrganizationEmail(organizationId: string, email: string, date: number) {
    return this.post(organizationUrl + '/update-email?date=' + date, {
      organizationId,
      email
    }).pipe(share());
  }

  updateOrganizationInfo(organizationInfo: OrganizationInfo) {
    return this.post(organizationUrl, organizationInfo).pipe(share());
  }

  updateAccountInfo(accountInfo: AccountInfo) {
    return this.post(accountInfoUrl, accountInfo).pipe(share());
  }

  updateAccountPhoneNumber(code: string) {
    return this.post(accountInfoUrl + '/update-phonenumber', {
      code
    }).pipe(share());
  }

  verifyPhoneNumber(phoneNumber: string) {
    return this.post(accountInfoUrl + '/verify-phonenumber', {
      phoneNumber
    }).pipe(share());
  }

  confirmEmail(email: string) {
    return this.post(
      organizationUrl + `/confirm-email?email=${email}`,
      null
    ).pipe(share());
  }

  getFailedAttemptCount(username: string): Observable<any> {
    return this.get(
      environment.apiUrl + `/failed-login-attempt-count/${username}`
    ).pipe(share());
  }
}
