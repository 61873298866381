import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable } from 'rxjs';

import { AuthenticationService } from '../../domains/auth/services/authentication.service';
import { BasketStatus } from '../../domains/basket/models/basket';
import { BasketSummary } from '../../domains/basket/models/basket-summary';
import { BasketService } from '../../domains/basket/services/basket.service';
import { Segment } from '../template/segments/model/segment';
import { SegmentsComponent } from '../template/segments/segments.component';

@Component({
  selector: 'app-shopping-cart-select',
  templateUrl: './shopping-cart-select.component.html',
  styleUrls: ['./shopping-cart-select.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShoppingCartSelectComponent implements OnInit {
  basketSummariesSubject = new BehaviorSubject<BasketSummary[]>([]);
  @Input() fullWidth: boolean = false;
  @Input() hideDelete: boolean = false;
  @Input() hideCurrent: boolean = false;
  @Input() hideSegments: boolean = false;
  @Input() selectionAsOutput: boolean = false;
  @Output() selectChange = new EventEmitter<any>();
  @Output() basketDeleted = new EventEmitter<string>();
  @Output() newBasketEnabled = new EventEmitter();
  @ViewChild('segmentsComponent') segmentsComponent:
    | SegmentsComponent
    | undefined;

  segments: Segment[] = [
    {
      id: 1,
      display: 'Aktif sepetler',
      count: 0,
      active: true,
      hide: false
    }
    // { id: 2, display: 'Teklif sepetleri', count: 0, active: false, hide: true }
  ];
  currentValue: BasketSummary | undefined;
  selection: BasketSummary | undefined;
  dropdownOpen: boolean = false;
  public get dropdownElement(): Element {
    return this.elementRef.nativeElement.querySelector('.dropdown-list');
  }

  constructor(
    private basketService: BasketService,
    private authenticationService: AuthenticationService,
    private cdr: ChangeDetectorRef,
    private elementRef: ElementRef,
    private permissionService: NgxPermissionsService
  ) {}

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.closeDropdown();
    }
  }

  ngOnInit(): void {
    if (this.authenticationService.isAuthorized()) {
      this.authenticationService.getUserInfo().subscribe((userInfo) => {
        this.permissionService.addPermission('SEPET', (accountId) => {
          return userInfo?.accountId === accountId;
        });

        // let offerPermission =
        //   this.permissionService.getPermission('OFFER_BASKET');
        // if (offerPermission) {
        //   this.segments[1].hide = false;
        // }
      });
    }

    this.basketService.basketSummariesSubject.subscribe((x) => {
      if (!x) {
        return;
      }

      let activeBaskets = x?.filter((y) => y.status === 0);
      // let offerBaskets = x.filter((y) => y.status > 0);

      this.segments[0].count = activeBaskets?.length;

      // if (!this.segments[1].hide) {
      //   this.segments[1].count = offerBaskets.length;
      // }

      this.basketSummariesSubject.next(activeBaskets);

      let filtered = x?.filter((y) => y.selected);

      this.currentValue = undefined;
      if (filtered?.length) {
        this.currentValue = filtered[0];
      }
    });
  }

  checkDeletePermission(accountId: string): Observable<boolean> {
    return new Observable<boolean>((observer) => {
      this.authenticationService.getUserInfo().subscribe((userInfo) => {
        observer.next(
          !!this.permissionService.getPermission('DELETE_BASKET') ||
            userInfo?.accountId === accountId
        );
      });
    });
  }

  closeDropdown() {
    this.dropdownElement.setAttribute('aria-expanded', 'false');
    this.dropdownOpen = false;
  }

  onSegmentChanged(segmentId: number) {
    if (segmentId === 1) {
      this.basketSummariesSubject.next(
        this.basketService.basketSummariesSubject.value.filter(
          (x) => x.status === 0
        )
      );
    } else if (segmentId === 2) {
      this.basketSummariesSubject.next(
        this.basketService.basketSummariesSubject.value.filter(
          (x) => x.status > 0
        )
      );
    }
  }

  getBasketStatus(status: BasketStatus) {
    if (status > 0) {
      if (status === BasketStatus.OfferAccepted) {
        return 'Teklif geldi';
      } else if (status === BasketStatus.OfferTimeout) {
        return 'Teklifin süresi doldu';
      } else if (status === BasketStatus.OfferWaiting) {
        return 'Teklif bekleniyor';
      } else if (status === BasketStatus.OfferRejected) {
        return 'Teklif reddedildi';
      }
    }

    return '';
  }

  selectByIndex(i: number) {
    let value = this.basketSummariesSubject.value[i];
    this.select(value);
  }

  select(value: BasketSummary) {
    if (!this.selectionAsOutput) {
      this.currentValue = value;
    } else {
      this.selection = value;
    }
    this.closeDropdown();
    this.selectChange.emit(value);
  }

  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
    this.segmentsComponent?.onClick(1);
  }

  onDeleteBasket(basketId: string) {
    this.basketDeleted.emit(basketId);
  }

  onEnableNewBasket() {
    this.newBasketEnabled.emit();
  }
}
