import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { CountdownModule } from 'ngx-countdown';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { SwiperModule } from 'swiper/angular';

import { CoreModule } from '../core/core.module';
import { AddressDrawerComponent } from './address-drawer/address-drawer.component';
import { DrawerComponent } from './drawer/drawer.component';
import { ErrorPageComponent } from './error/error-page.component';
import { ImageThumbnailsComponent } from './image-thumbnails/image-thumbnails.component';
import { InfiniteScrollComponent } from './infinite-scroll/infinite-scroll.component';
import { InstallmentComponent } from './installment/installment.component';
import { LoadingComponent } from './loading/loading.component';
import { MessageBoxComponent } from './message-box/message-box.component';
import { InitialPageComponent } from './mini-cart/initial-page/initial-page.component';
import { MiniCartComponent } from './mini-cart/mini-cart.component';
import { MiniCartValidationComponent } from './mini-cart/mini-cart-validation/mini-cart-validation.component';
import { MinicartItemComponent } from './mini-cart/minicart-item/minicart-item.component';
import { UpsertBasketComponent } from './mini-cart/upsert-basket/upsert-basket.component';
import { MobileSideMenuComponent } from './mobile-side-menu/mobile-side-menu.component';
import { NoPriceComponent } from './no-price/no-price.component';
import { NoPriceShownWarningComponent } from './no-price-shown-warning/no-price-shown-warning.component';
import { OrderVariantsComponent } from './order-variants/order-variants.component';
import { PanelComponent } from './panel/panel.component';
import { PanelBodyComponent } from './panel/panel-body/panel-body.component';
import { PanelFooterComponent } from './panel/panel-footer/panel-footer.component';
import { PanelHeaderComponent } from './panel/panel-header/panel-header.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { ProductVariantsComponent } from './product-variants/product-variants.component';
import { PropertyComponent } from './property/property.component';
import { SalesAgreementComponent } from './sales-agreement/sales-agreement.component';
import { SeperatorComponent } from './seperator/seperator.component';
import { ShoppingCartSelectComponent } from './shopping-cart-select/shopping-cart-select.component';
import { SmallProductCardComponent } from './small-product-card/small-product-card.component';
import { StarComponent } from './star/star.component';
import { StickyBasketComponent } from './sticky-basket/sticky-basket.component';
import { SwipeComponent } from './swipe/swipe.component';
import { TemplateModule } from './template/template.module';
import { ZoomComponent } from './template/zoom/zoom.component';
import { TimerComponent } from './timer/timer.component';
@NgModule({
  declarations: [
    StarComponent,
    SmallProductCardComponent,
    SwipeComponent,
    MobileSideMenuComponent,
    DrawerComponent,
    MessageBoxComponent,
    AddressDrawerComponent,
    ZoomComponent,
    ErrorPageComponent,
    ShoppingCartSelectComponent,
    StickyBasketComponent,
    PanelComponent,
    PanelBodyComponent,
    PanelHeaderComponent,
    ImageThumbnailsComponent,
    PropertyComponent,
    ProductDetailComponent,
    OrderVariantsComponent,
    SeperatorComponent,
    LoadingComponent,
    TimerComponent,
    MiniCartComponent,
    InitialPageComponent,
    UpsertBasketComponent,
    MiniCartValidationComponent,
    MinicartItemComponent,
    ProductVariantsComponent,
    InfiniteScrollComponent,
    PanelFooterComponent,
    NoPriceShownWarningComponent,
    SalesAgreementComponent,
    NoPriceComponent,
    InstallmentComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    SwiperModule,
    TemplateModule,
    FormsModule,
    CountdownModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    NgxSkeletonLoaderModule,
    NgxPermissionsModule.forChild()
  ],
  providers: [CookieService],
  exports: [
    CommonModule,
    StarComponent,
    SmallProductCardComponent,
    SwipeComponent,
    MobileSideMenuComponent,
    SwiperModule,
    DrawerComponent,
    MessageBoxComponent,
    AddressDrawerComponent,
    ErrorPageComponent,
    NgxPermissionsModule,
    TemplateModule,
    NoPriceShownWarningComponent,
    NoPriceComponent,
    StickyBasketComponent,
    ZoomComponent,
    ShoppingCartSelectComponent,
    PanelComponent,
    PropertyComponent,
    ImageThumbnailsComponent,
    ProductDetailComponent,
    OrderVariantsComponent,
    MiniCartComponent,
    InitialPageComponent,
    UpsertBasketComponent,
    MiniCartValidationComponent,
    MinicartItemComponent,
    SeperatorComponent,
    PanelBodyComponent,
    PanelHeaderComponent,
    TimerComponent,
    LoadingComponent,
    ProductVariantsComponent,
    InfiniteScrollComponent,
    SalesAgreementComponent,
    PanelFooterComponent,
    InstallmentComponent
  ]
})
export class PartialsModule {}
