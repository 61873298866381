import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation
} from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';

import { environment } from '../../../../environments/environment';
import { normalizeString } from '../../../core/services/utils.service';
import { AuthenticationService } from '../../../domains/auth/services/authentication.service';
import { BasketStatus } from '../../../domains/basket/models/basket';
import { BasketSummary } from '../../../domains/basket/models/basket-summary';
import {
  BasketItemSummary,
  BasketItemVariant
} from '../../../domains/basket/models/basketitem-summary';
import { BasketService } from '../../../domains/basket/services/basket.service';

@Component({
  selector: 'app-minicart-item',
  templateUrl: './minicart-item.component.html',
  styleUrls: ['./minicart-item.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MinicartItemComponent {
  @Input() items: BasketItemSummary[] = [];
  @Input() disable: boolean = false;
  @Input() selectedBasket: BasketSummary | undefined;
  @Output() removeItem = new EventEmitter<BasketItemSummary>();
  @Output() productRouteRedirected = new EventEmitter<boolean>();
  fileUrl = environment.fileApiUrl;
  props: string = '';
  constructor(
    private basketService: BasketService,
    private permissionService: NgxPermissionsService,
    private authenticationService: AuthenticationService
  ) {}

  removeCartItem(event: BasketItemSummary) {
    this.removeItem.emit(event);
  }

  onCounterChange(count: number, item: BasketItemSummary) {
    if (count > 0) {
      this.basketService
        .addItemToBasket({
          productId: item.productId,
          countryCode: 'TR',
          currencyCode: 'TRY',
          quantity: count,
          attributeItemIds: item.variants.map((x) => x.attributeItemId)
        })
        .subscribe();
    } else if (count < 0) {
      this.basketService
        .removeItemsFromBasket({
          productId: item.productId,
          attributeItemIds: item.variants.map((x) => x.attributeItemId),
          quantity: Math.abs(count)
        })
        .subscribe();
    }
  }

  getVariantsView(option: BasketItemSummary) {
    return option.variants
      .map((x: BasketItemVariant) => x.attributeItemName)
      .join(' / ');
  }

  getVariants(item: BasketItemSummary) {
    return item.variants.map((x) => x.attributeItemId);
  }

  checkDeletePermission(item: BasketItemSummary) {
    return (
      !!this.permissionService.getPermission('DELETE_BASKET') || !item.disabled
    );
  }

  checkDisableIncrease(item: BasketItemSummary) {
    return (
      item.disabled ||
      !(
        this.selectedBasket?.status === BasketStatus.Active ||
        this.selectedBasket?.status === BasketStatus.OfferAccepted
      )
    );
  }

  checkDisableDecrease(item: BasketItemSummary) {
    return item.disabled || this.selectedBasket?.status != BasketStatus.Active;
  }

  trackByFn(index: number, item: BasketItemSummary) {
    return item._id;
  }

  getVariantsValues(item: BasketItemSummary) {
    return item.variants
      .map((x) => x.attributeItemName)
      .sort((a, b) => a.localeCompare(b))
      .join('-');
  }

  getProductSchemanticKey(item: BasketItemSummary) {
    return encodeURIComponent(
      normalizeString(
        `${item.brand}-${item.productTitle}-${this.getVariantsValues(item)}`
      )
    );
  }
}
