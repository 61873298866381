export const environment = {
  production: true,
  apiUrl: 'https://api.dtasistan.com',
  identityApiUrl: 'https://identityapi.dtasistan.com',
  recaptchaKey: '6LeetVQhAAAAAEEmRt0dC5oR8zSM9-nO95D5xr9w',
  fileApiUrl: 'https://api.dtasistan.com/api/file/global/product-images',
  socketEnabled: false,
  priceEnabledWithoutLogin: false,
  webSite: 'https://www.dtasistan.com'
};
