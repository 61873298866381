<div
  class="item-counter {{ counterType }}"
  [ngClass]="disableIncrease && disableDecrease ? 'disabled' : ''"
>
  <div class="count">
    <a
      class="button"
      (click)="onClickMinus()"
      [ngClass]="{ disabled: disableDecrease }"
      >-</a
    >
    <div
      class="total"
      [ngClass]="{
        focused: isFocus,
        'total-enabled': !disableIncrease && !disableDecrease
      }"
    >
      <p>
        <input
          #counterInput
          type="number"
          class="input-count"
          min="1"
          onkeypress="return
        event.charCode >= 48"
          [value]="_counter"
          (blur)="onBlur($event)"
          (focus)="onFocus($event)"
          (input)="onInput()"
          (keyup.enter)="onEnter($event)"
          [ngClass]="{
            'input-count-enabled': !disableIncrease && !disableDecrease
          }"
        />
      </p>
      <span *ngIf="!isFocus">Adet</span>
    </div>
    <a
      class="button"
      (click)="onClickPlus()"
      *ngIf="!hideIncreaseButton"
      [ngClass]="{ 'button-enabled': !disableIncrease }"
      >+</a
    >
  </div>
</div>
