import { CurrencyPipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, share } from 'rxjs/operators';

import { environment } from '../../../../environments/environment';
import { ServiceBase } from '../../../../lib/core/services/service.base';
import { SignalRService } from '../../../../lib/core/services/signalR.service';
import { PriceRequest } from '../models/price-request';

const stockBaseUrl = environment.apiUrl + '/api/price';

@Injectable({ providedIn: 'root' })
export class PriceService extends ServiceBase {
  constructor(
    http: HttpClient,
    signalRService: SignalRService,
    private currencyPipe: CurrencyPipe
  ) {
    super(http, signalRService);
  }

  showPrice$ = new BehaviorSubject<boolean>(false);

  setShowPrice(value: boolean) {
    this.showPrice$.next(value);
  }

  getPrice(request: PriceRequest): Observable<string | null> {
    return this.post<number | null>(stockBaseUrl, request)
      .pipe(
        map((result: number | null) => {
          if (typeof result === 'number') {
            return (
              '₺ ' + this.currencyPipe.transform(result, 'TRY', '', '1.2-2')
            );
          }
          return null;
        })
      )
      .pipe(share());
  }
}
