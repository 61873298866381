import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output
} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent implements OnInit {
  @Input()
  imageLink: string|undefined = undefined;
  @Input()
  title: string = '';
  @Input()
  bodyText: string = '';
  @Input()
  onlyMobile: boolean = false;

  @Input()
  isHTML: boolean = false;

  @Input()
  size: 'sm' | 'md' | 'lg' = 'sm';

  _isModalOpen: boolean = false;

  @Input()
  set isOpen(value: boolean) {
    this._isModalOpen = value;
    let htmlTag = this.document.querySelectorAll('html')[0];

    if (value) {
      htmlTag?.classList.add('overflow-hidden');
    } else {
      htmlTag?.classList.remove('overflow-hidden');
    }
  }

  @Output()
  isOpenChange = new EventEmitter<boolean>(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  close() {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }

  ngOnInit(): void {}
}
