import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Output
} from '@angular/core';

@Component({
  selector: 'app-drawer',
  templateUrl: './drawer.component.html',
  styleUrls: ['./drawer.component.scss']
})
export class DrawerComponent {
  @Input()
  title: string = '';

  @HostBinding('class')
  classes = 'drawer';

  @Input()
  width: number = 360;

  @Input()
  customHeader: boolean = false;

  @Input()
  shadow: boolean = true;

  @Input()
  direction: 'left' | 'right' = 'right';

  _isDrawerOpen: boolean = false;

  @Input()
  set isOpen(value: boolean) {
    this._isDrawerOpen = value;
    let htmlTag = this.document.querySelectorAll('html')[0];

    if (value) {
      htmlTag?.classList.add('overflow-hidden');
    } else {
      htmlTag?.classList.remove('overflow-hidden');
    }
  }

  @Output()
  isOpenChange = new EventEmitter<boolean>(false);

  constructor(@Inject(DOCUMENT) private document: Document) {}

  close() {
    this.isOpen = false;
    this.isOpenChange.emit(false);
  }
}
