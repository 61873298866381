import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Address } from '../../domains/account/models/address';
import { CreditCardType } from '../../domains/checkout/models/credit-card-type';
import { Variant } from '../../domains/product/models/variant';
import { VariantCombination } from '../../domains/product/models/variant-combination';
declare var window: Window;
const escapeChars = ['+', ':', 'x', '%', 'Ø', '(', ')', '/', 'µm'];
@Injectable({ providedIn: 'root' })
export class UtilsService {
  constructor() {}

  /**
   * Build url parameters key and value pairs from array or object
   * @param obj
   */
  urlParam(obj: any): string {
    return Object.keys(obj)
      .filter(
        (c) =>
          typeof obj[c] === 'string' ||
          typeof obj[c] === 'number' ||
          typeof obj[c] === 'boolean' ||
          obj[c] instanceof Date
      )
      .map(
        (k) =>
          k +
          '=' +
          encodeURIComponent(
            obj[k] instanceof Date
              ? JSON.stringify(obj[k]).substr(
                  1,
                  JSON.stringify(obj[k]).length - 2
                )
              : obj[k]
          )
      )
      .join('&');
  }

  /**
   * Simple object check.
   * @param item
   * @returns {boolean}
   */
  isObject(item: any) {
    return item && typeof item === 'object' && !Array.isArray(item);
  }

  /**
   * Deep merge two objects.
   * @param target
   * @param ...sources
   * @see https://stackoverflow.com/a/34749873/1316921
   */
  mergeDeep(target: { [x: string]: any }, ...sources: any[]): any {
    if (!sources.length) {
      return target;
    }
    const source = sources.shift();

    if (this.isObject(target) && this.isObject(source)) {
      for (const key in source) {
        if (this.isObject(source[key])) {
          if (!target[key]) {
            Object.assign(target, { [key]: {} });
          }
          this.mergeDeep(target[key], source[key]);
        } else {
          Object.assign(target, { [key]: source[key] });
        }
      }
    }

    return this.mergeDeep(target, ...sources);
  }

  getPath(obj: { [x: string]: any }, val: any, path?: string): string {
    path = path || '';
    let fullpath = '';
    for (const b in obj) {
      if (obj[b] === val) {
        return path + '/' + b;
      } else if (typeof obj[b] === 'object') {
        fullpath = this.getPath(obj[b], val, path + '/' + b) || fullpath;
      }
    }
    return fullpath;
  }

  getFindHTTPParams(queryParams: {
    filter: string | number | boolean;
    sortOrder: string | number | boolean;
    sortField: string | number | boolean;
    pageNumber: { toString: () => string | number | boolean };
    pageSize: { toString: () => string | number | boolean };
  }): HttpParams {
    const params = new HttpParams()
      .set('lastNamefilter', queryParams.filter)
      .set('sortOrder', queryParams.sortOrder)
      .set('sortField', queryParams.sortField)
      .set('pageNumber', queryParams.pageNumber.toString())
      .set('pageSize', queryParams.pageSize.toString());

    return params;
  }

  getHTTPHeader() {
    return {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };
  }

  getTextFromPhone(value: string): string {
    return value?.replace(/[- )(]/g, '');
  }

  getPhoneFromText(value: string): string {
    if (value == null) {
      return '';
    }
    let newVal = value.replace(/\D/g, '');
    if (newVal.length <= 3) {
      newVal = newVal.substring(0, newVal.length - 1);
    }
    if (newVal.length === 0) {
      newVal = '';
    } else if (newVal.length <= 3) {
      newVal = newVal.replace(/^(\d{0,3})/, '($1)');
    } else if (newVal.length <= 6) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})/, '($1) $2');
    } else if (newVal.length <= 8) {
      newVal = newVal.replace(/^(\d{0,3})(\d{0,3})(\d{0,2})/, '($1) $2 $3');
    } else {
      newVal = newVal.replace(
        /^(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/,
        '($1) $2 $3 $4'
      );
    }

    return newVal;
  }

  getCardType(value: string): CreditCardType {
    let cardType = CreditCardType.UNKNOWN;
    if (value != '') {
      let oneValue = parseInt(value.substring(0, 1));
      let twoValue = parseInt(value.substring(0, 2));
      //let threeValue = parseInt(value.substring(0, 3));
      let fourValue = parseInt(value.substring(0, 4));

      if (oneValue == 4) {
        cardType = CreditCardType.VISA;
      } else if (
        (fourValue >= 2221 && fourValue <= 2720) ||
        (twoValue >= 51 && twoValue <= 55)
      ) {
        cardType = CreditCardType.MASTER_CARD;
      }
    }
    return cardType;
  }

  getPasswordMeter(password: string): number {
    let strength: number = 0;
    let passwordStrength: number = 0;
    let variations = {
      digits: /\d/.test(password),
      lower: /[a-z]/.test(password),
      upper: /[A-Z]/.test(password),
      nonWords: /\W/.test(password)
    };

    strength += variations.digits ? 1 : 0;
    strength += variations.lower ? 1 : 0;
    strength += variations.upper ? 1 : 0;
    if (strength < 3 || password.length < 5) {
      return 1;
    }

    strength = password.length;

    if (strength > 5 && !variations.nonWords) {
      strength = 6;
    } else if (strength > 5 && variations.nonWords) {
      strength = 9;
    }

    passwordStrength = parseInt((strength / 3).toString());
    return passwordStrength;
  }

  base64ToFile(base64Image: string): Blob {
    const split = base64Image.split(',');
    const type = split[0].replace('data:', '').replace(';base64', '');
    const byteString = split[1];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i += 1) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type });
  }

  getAddressText(address: Address | undefined): string {
    if (!address) return '';
    return `${address.addressEntities[2].name} Mh. ${address.addressText} ${address.addressEntities[0].name}/${address.addressEntities[1].name}`;
  }

  getDateDiff(date1: Date, date2: Date): number {
    return Math.floor(
      (date1.getTime() - date2.getTime()) / (1000 * 60 * 60 * 24)
    );
  }

  getDiscountedPrice(
    price: number,
    discount: number | null,
    discountRate: number | null
  ) {
    let discountAmount = 0;
    if (discountRate && discountRate > 0) {
      discountAmount = price * discountRate;
    } else if (discount && discount > 0) {
      discountAmount = discount;
    }
    if (price > discountAmount) {
      return price - discountAmount;
    }
    return 0;
  }

  getDiscountedRate(
    totalPrice: number,
    rate: number,
    discount: number | null | undefined,
    discountRate: number | null | undefined
  ): number {
    let rateValue = rate / 100;

    if (discount && discount > 0) {
      var totalpriceWithCarryingCharge = Math.max(
        totalPrice,
        totalPrice / (1 - rateValue) - discount
      );

      return (
        (totalpriceWithCarryingCharge - totalPrice) /
        totalpriceWithCarryingCharge
      );
    }
    return rateValue * (1 - discountRate!);
  }

  getCarryingCharge(price: number, percentRate: number): number {
    return (price * percentRate) / 100;
  }
  getPriceWithCarryingCharge(price: number, percentRate: number): number {
    return price + this.getCarryingCharge(price, percentRate);
  }
}

export function isInteger(value: any): value is number {
  return (
    typeof value === 'number' && isFinite(value) && Math.floor(value) === value
  );
}

export function isString(value: any): value is string {
  return typeof value === 'string';
}

export function groupBy(xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

export function print(title: string, css: string, innerHTML: string): void {
  let popupWin;
  popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
  popupWin?.document.write(`
  <html>
    <head>
      <title>${title}</title>
      <style>
      ${css}
      </style>
    </head>
    <body onload="window.print();window.close()">${innerHTML}</body>
    </html>`);
  popupWin?.document.close();
}

export function normalizeString(str: string): string {
  str = str
    .replace(/ş/g, 's')
    .replace(/Ş/g, 'S')
    .replace(/ı/g, 'i')
    .replace(/İ/g, 'I')
    .replace(/ğ/g, 'g')
    .replace(/Ğ/g, 'G')
    .replace(/ö/g, 'o')
    .replace(/Ö/g, 'O')
    .replace(/ü/g, 'u')
    .replace(/Ü/g, 'U')
    .replace(/ç/g, 'c')
    .replace(/Ç/g, 'C');

  let normalizedStr = Array.from(str)
    .map((x) =>
      (x >= 'a' && x <= 'z') ||
      (x >= 'A' && x <= 'Z') ||
      (x >= '0' && x <= '9') ||
      escapeChars.includes(x)
        ? x
        : '-'
    )
    .join('')
    .toLowerCase();

  while (normalizedStr.includes('--')) {
    normalizedStr = normalizedStr.replace('--', '-');
  }

  return normalizedStr.replace(/^-+|-+$/g, '');
}

function findCombinations(
  arrays: VariantCombination[][]
): VariantCombination[][] {
  if (arrays.length === 0) {
    return [[]];
  }

  const firstArray = arrays[0];
  const remainingArrays = arrays.slice(1);

  const combinationsWithoutFirst = findCombinations(remainingArrays);
  const combinationsWithFirst = [];

  for (const value of firstArray) {
    for (const combination of combinationsWithoutFirst) {
      combinationsWithFirst.push([value, ...combination]);
    }
  }
  return combinationsWithFirst;
}

export function getVariantCombination(variants: Variant[]) {
  let variantValues: VariantCombination[][] = [];
  variants.forEach((variant) => {
    let variantItem: VariantCombination[] = [];
    variant.items.forEach((item) => {
      variantItem.push({
        variantName: variant.name,
        variantValue: item.name
      });
    });
    variantValues.push(variantItem);
  });
  return findCombinations(variantValues);
}
