import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { AccountSummary } from '../models/account-summary';

declare let localStorage: any;

@Injectable({ providedIn: 'root' })
export class TokenStorage {
  /**
   * Get access token
   * @returns {Observable<string>}
   */
  public getAccessToken(): string {
    return <string>localStorage.getItem('accessToken');
  }

  /**
   * Get refresh token
   * @returns {Observable<string>}
   */
  public getRefreshToken(): string {
    return <string>localStorage.getItem('refreshToken');
  }

  /**
   * Get user roles in JSON string
   * @returns {Observable<any>}
   */
  public getUserRoles(): Observable<any> {
    const roles: any = localStorage.getItem('userRoles');

    return of(JSON.parse(roles));
  }

  /**
   * Set access token
   * @returns {TokenStorage}
   */
  public setAccessToken(token: string): TokenStorage {
    localStorage.setItem('accessToken', token);

    return this;
  }

  /**
   * Set refresh token
   * @returns {TokenStorage}
   */
  public setRefreshToken(token: string): TokenStorage {
    localStorage.setItem('refreshToken', token);

    return this;
  }

  /**
   * Set user roles
   * @param roles
   * @returns {TokenStorage}
   */
  public setUserRoles(roles: any): any {
    if (roles != null) {
      localStorage.setItem('userRoles', JSON.stringify(roles));
    }

    return this;
  }

  public setUserInfo(info: AccountSummary): any {
    if (info != null) {
      localStorage.setItem('userInfo', JSON.stringify(info));
    }

    return this;
  }

  public getUserInfo(): AccountSummary {
    const userInfo: any = localStorage.getItem('userInfo');

    return JSON.parse(userInfo);
  }

  public getRememberData() {
    const rememberInfo: any = localStorage.getItem('rememberInfo');

    return JSON.parse(rememberInfo) ?? {};
  }

  public setRememberData(remember: boolean, username: string) {
    localStorage.setItem(
      'rememberInfo',
      JSON.stringify({
        remember,
        username
      })
    );
  }

  /**
   * Remove tokens
   */
  public clear() {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('userInfo');
    localStorage.removeItem('userRoles');
  }
}
