export class Basket {
  id: string = '';
  cargoPrice: number | null = null;
  cargoPriceCampaignCode: string = '';
  cargoPriceCampaignDiscount: number = 0;
  cargoPriceCampaignDiscountRate: number = 0;
  totalAmountCampaignCode: string = '';
  totalAmountCampaignDiscount: number = 0;
  totalAmountCampaignDiscountRate: number = 0;
  totalAmount: number = 0;
  name: string = '';
  offerDate: Date = new Date();
  offerAcceptDate: Date = new Date();
  offerDeadline: Date = new Date();
  organizationId: string = '';
  accountId: string = '';
  options: Option[] = [];
  status: BasketStatus = BasketStatus.Active;
}

export class Option {
  productId: string = '';
  productTitle: string = '';
  brand: string = '';
  imageUrl: string = '';
  items: BasketItem[] = [];
  variants: Variant[] = [];
}

export class Variant {
  attributeId: string = '';
  attributeItemId: string = '';
  attributeName: string = '';
  attributeItemName: string = '';
}

export class BasketItem {
  price: number = 0;
  accountId: string = '';
  accountFirstName: string = '';
  accountLastName: string = '';
  shippingTime: number = 0;
  currencyCode: string = '';
  campaignCode: string = '';
  campaignDiscount: number = 0;
  campaignDiscountRate: number = 0;
}

export enum BasketStatus {
  Active = 0,
  OfferWaiting = 1,
  OfferAccepted = 2,
  OfferCanceled = 3,
  OfferTimeout = 4,
  OfferRejected = 5
}
