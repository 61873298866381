<div class="shopping-cart-select-wrapper">
  <button
    class="dropdown-button"
    aria-haspopup="listbox"
    aria-labelledby="dropdown-label"
    #shoppingCartSelect
    (click)="toggleDropdown()"
    [ngClass]="{ opened: dropdownOpen }"
    (clickOutside)="dropdownOpen = false"
  >
    <div class="cart-info__img">
      <img
        src="../../../assets/images/cart/cart-icon.svg"
        alt="shopping-cart"
      />
      <img
        src="../../../assets/images/cart/basket-status/{{
          currentValue?.status
        }}.svg"
        alt="{{ currentValue?.status }}"
        class="cart-status cart-status-{{ currentValue?.status }}"
        *ngIf="(currentValue && currentValue.status > 0) || false"
      />
    </div>
    <div class="cart-info" *ngIf="!hideCurrent && currentValue">
      <span id="dropdown-label" class="dropdown-label"
        >{{ currentValue.name }}
      </span>
      <span class="badge"
        ><span class="count">{{ currentValue.count }}</span> ürün
        <span class="price"
          >₺ {{ currentValue.total | currency : 'TRY' : '' : '1.2-2' }}</span
        ></span
      >
    </div>
    <div class="cart-info" *ngIf="hideCurrent && selection">
      <span id="dropdown-label" class="dropdown-label"
        >{{ selection.name }}
      </span>
      <span class="badge"
        ><span class="count">{{ selection.count }}</span> ürün
        <span class="price"
          >₺ {{ selection.total | currency : 'TRY' : '' : '1.2-2' }}</span
        ></span
      >
    </div>
    <div class="cart-info" *ngIf="(hideCurrent && !selection) || !currentValue">
      <span class="dropdown-label">Sepet seçiniz</span>
    </div>
  </button>
  <div class="list-container">
    <ul
      class="dropdown-list"
      role="listbox"
      aria-expanded="false"
      [ngClass]="{ 'dropdown-open': dropdownOpen }"
    >
      <div class="segments">
        <app-segments
          #segmentsComponent
          [segments]="segments"
          *ngIf="!hideSegments"
          (segmentChanged)="onSegmentChanged($event)"
        >
        </app-segments>
      </div>
      <div class="list-items">
        <ng-container *ngFor="let o of basketSummariesSubject | async">
          <li
            *ngIf="!(hideCurrent && currentValue?.id === o.id)"
            [ngClass]="{ selected: currentValue?.id === o.id }"
            tabindex="0"
            role="option"
          >
            <div class="cart-info" (click)="select(o)">
              <span id="dropdown-label" class="dropdown-label"
                >{{ o.name }}
              </span>
              <span class="badge"
                ><b>{{ o.count }}</b> ürün
                <span class="price"
                  >₺ {{ o.total | currency : 'TRY' : '' : '1.2-2' }}</span
                ></span
              >
              <span
                class="basket-status basket-status-{{ o.status }}"
                *ngIf="o.status > 0"
              >
                <img
                  src="../../../assets/images/cart/basket-status/{{
                    o.status
                  }}.svg"
                  alt="{{ o.status }}"
                />
                {{ getBasketStatus(o.status) }}
              </span>
            </div>
            <img
              src="../../../assets/images/cart/delete-black.svg"
              height="18px"
              width="14px"
              alt="delete"
              class="delete-icon"
              (click)="onDeleteBasket(o.id)"
              *ngIf="
                (checkDeletePermission(o.accountId) | async) && !hideDelete
              "
            />
          </li>
        </ng-container>
        <hr class="dropdown-list-hr" />
      </div>

      <div class="dropdown-list__footer">
        <app-link-component
          size="md"
          color="noborder"
          label="Sepet oluştur"
          (linkClicked)="onEnableNewBasket()"
          icon="../../../assets/images/cart/add-icon.svg"
          (click)="onEnableNewBasket()"
        >
        </app-link-component>
      </div>
    </ul>
  </div>

  <app-modal title="Sepet seç" [(isOpen)]="dropdownOpen" [onlyMobile]="true">
    <app-segments
      [segments]="segments"
      *ngIf="!hideSegments"
      (segmentChanged)="onSegmentChanged($event)"
    >
    </app-segments>
    <ul class="mini-cart-modal">
      <ng-container
        *ngFor="let o of basketSummariesSubject | async; let i = index"
      >
        <li
          *ngIf="!(hideCurrent && currentValue?.id === o.id)"
          [ngClass]="{ selected: currentValue === o }"
          tabindex="0"
          role="option"
        >
          <div class="cart-info" (click)="select(o)">
            <span id="dropdown-label" class="dropdown-label"
              >{{ o.name }}
            </span>
            <span class="badge"
              ><b>{{ o.count }}</b> ürün
              <span class="price"
                >₺ {{ o.total | currency : 'TRY' : '' : '1.2-2' }}</span
              ></span
            >
            <span
              class="basket-status basket-status-{{ o.status }}"
              *ngIf="o.status > 0"
            >
              <img
                src="../../../assets/images/cart/basket-status/{{
                  o.status
                }}.svg"
                alt="{{ o.status }}"
              />

              {{ getBasketStatus(o.status) }}
            </span>
          </div>
          <img
            src="../../../assets/images/cart/delete-black.svg"
            height="18px"
            width="14px"
            alt="delete"
            class="delete-icon"
            (click)="onDeleteBasket(o.id)"
            *ngIf="(checkDeletePermission(o.accountId) | async) && !hideDelete"
          />
        </li>
      </ng-container>
    </ul>
    <div class="modal__cta modal__cta">
      <app-link-component
        size="md"
        color="blue"
        label="Sepet oluştur"
        (linkClicked)="onEnableNewBasket()"
      >
      </app-link-component>
    </div>
  </app-modal>
</div>
