<div
  [ngClass]="{ opened: _isModalOpen, onlyMobile: onlyMobile }"
  class="modal-overlay"
  (click)="close()"
></div>
<div
  [ngClass]="{ row_open: _isModalOpen, onlyMobile: onlyMobile }"
  class="modal__row"
>
  <div class="modal__container {{ size }}">
    <div class="modal__content">
      <div class="modal__close" *ngIf="imageLink" (click)="close()">
        <img
          [src]="imageLink"
          width="24"
          height="24"
          alt="close icon"
          title="close icon"
        />
      </div>
      <div class="modal__text" *ngIf="title || bodyText">
        <div class="title" *ngIf="title">
          {{ title }}
        </div>
        <div class="body" *ngIf="bodyText">
          <div [innerHTML]="bodyText | safe: 'SafeHtml'" *ngIf="isHTML"></div>
          <div *ngIf="!isHTML">{{ bodyText }}</div>
        </div>
      </div>
      <ng-content></ng-content>
    </div>
  </div>
</div>

<!-- Örnek Component Çağırılması -->
<!-- btnCount 3'e kadar desteklemektedir. 3 adet butonumuz oluyor. Tekli gösterimde sadece approve, ikili gösterimde approve ve cancel, üçlü gösterimde hepsi aktif olur. -->
<!-- <app-modal
  title="Header"
  bodyText="Body text"
  imageLink="assets/images/modal-image.svg"
  btnCount="1"
  btnApprove="Onayla"
  btnCancel=""
  btnOther=""
  [(isOpen)]="isModalOpen"
>
  <span>Content</span>
  <div class="modal__cta modal__cta--3">
    <a class="btn-default btn-md btn-default--noborder">Diğer</a>
    <a class="btn-default btn-md btn-default--white" (click)="close()"
      >Vazgeç</a
    >
    <a class="btn-default btn-md btn-default--blue">Onayla</a>
  </div>
</app-modal> -->
