import {
  Component,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewEncapsulation
} from '@angular/core';

@Component({
  selector: 'app-link-component',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LinkComponent implements OnInit, OnChanges {
  @Input() label: string = '';
  @Input() size: string = '';
  @Input() color = 'blue';
  @Input() icon: string = '';
  @Input() disabled: boolean = false;
  @Input() noPad: boolean = false;
  @Input() iconLocation: string = 'right';
  @Input() imageClass: string = '';
  @Input() class: string = '';
  @Input() isBold: boolean = false;
  @Input() hideLabelForMobile: boolean = false;
  @Output() linkClicked = new EventEmitter<any>();

  @HostBinding('class')
  selectedClass: string = '';

  @HostListener('click', ['$event'])
  onLinkClicked(event: Event) {
    this.linkClicked.emit(event);
  }

  ngOnInit(): void {
    this.getClass();
  }

  ngOnChanges(): void {
    this.getClass();
  }

  getClass() {
    this.selectedClass = `btn-default btn-${this.size} btn-default--${this.color}`;
    if (this.icon?.length > 0) {
      this.selectedClass += ` has-icon`;
    }
    if (this.noPad) {
      this.selectedClass += ` btn-default--no-pad `;
    }
    if (this.isBold) {
      this.selectedClass += ` btn-default--bold `;
    }
    if (this.disabled) {
      this.selectedClass += ` disabled `;
    }
  }
}
