<div class="mc-items" *ngFor="let item of items; trackBy: trackByFn">
  <div class="mc-items__top">
    <a
      [routerLink]="['/product-detail', getProductSchemanticKey(item)]"
      (click)="productRouteRedirected.emit(true)"
    >
      <div class="image">
        <img
          [src]="
            item.imageUrl
              ? fileUrl + '/minicart_img/' + item.imageUrl
              : '/assets/images/cart/item-image-not-found.svg'
          "
          width="48"
          height="48"
          [alt]="item.productTitle + 'image'"
          [title]="item.productTitle + 'image'"
        />
      </div>
      <div class="details">
        <span
          ><b>{{ item.brand }}</b> {{ item.productTitle }}
        </span>
        <span class="blue">{{ getVariantsView(item) }}</span>
      </div>
    </a>
    <a class="remove-item" (click)="removeCartItem(item)">
      <img
        src="/assets/images/wastebasket.svg"
        width="24"
        height="24"
        alt="wastebasket"
        title="wastebasket"
        *ngIf="checkDeletePermission(item)"
      />
    </a>
  </div>
  <div class="mc-items__bottom">
    <app-item-counter
      counterType="gray"
      [counter]="item.count"
      (counterChange)="onCounterChange($event, item)"
      [disableIncrease]="checkDisableIncrease(item)"
      [disableDecrease]="checkDisableDecrease(item)"
    ></app-item-counter>
    <div class="total-count">
      <p>Toplam</p>
      <span>₺ {{ item.total | currency : 'TRY' : '' : '1.2-2' }}</span>
    </div>
  </div>
</div>
